/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  ShowHomesFiltersComponent,
  FiltersWrapper,
  ClearButton,
  SearchButton,
  MobileFilterButton,
  ViewToggle,
  MobileFilterMenu,
  CloseButton,
  ApplyButton,
  ButtonsWrapper,
  MobileButtonsWrapper,
  DropdownsWrapper
} from './ShowHomesFilters.styles.js';
import SearchInput from './SearchInput.jsx';
import Dropdown from './Dropdown.jsx';
import FilterIcon from '../../assets/icons/Filter.svg';
import styled from 'styled-components';
import { minBp } from '../../lib/theme.js';
import { useShowHomes } from '../../context/ShowHomesContext';

const Wrapper = styled.div`
  background: white;

  @media ${minBp('tabletLarge')} {
    display: flex;
    align-items: center;
    background: #f2f2f2;
  }
`;

const ShowHomesFilters = ({ onSearch, onViewChange }) => {
  const { state, dispatch } = useShowHomes();
  const { labels, results, radius: stateRadius, searchTerm } = state;
  const { Filters = {} } = results ?? {};
  const { PropertyTypes = [], Bedrooms = [], Radiuses = [] } = Filters;

  const [location, setLocation] = useState('');
  const [selectedBedrooms, setSelectedBedrooms] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [viewMode, setViewMode] = useState('list');
  const isInitialLoad = useRef(true);

  useEffect(() => {
    const handleResize = () => {
      if (isFilterMenuOpen) {
        setIsFilterMenuOpen(false);
        document.body.style.overflow = 'auto';
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (isFilterMenuOpen) {
        document.body.style.overflow = 'auto';
      }
    };
  }, [isFilterMenuOpen]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const termParam = params.get('term');
    const radiusParam = params.get('radius') || '40';
    const bedroomsParams = params.getAll('bedrooms');
    const propertyTypesParam = params.get('propertytypes');

    if (termParam) setLocation(termParam);
    dispatch({ type: 'SET_RADIUS', payload: radiusParam });
    if (bedroomsParams.length) setSelectedBedrooms(bedroomsParams);
    if (propertyTypesParam)
      setSelectedProperties(propertyTypesParam.split(','));

    if (isInitialLoad.current) {
      dispatch({
        type: 'SET_SEARCH_TERM',
        payload: termParam || '',
        bedrooms: bedroomsParams,
        propertytypes: propertyTypesParam ? propertyTypesParam.split(',') : [],
        radius: radiusParam
      });

      // Trigger the search with all initial parameters
      if (
        termParam ||
        bedroomsParams.length ||
        radiusParam ||
        propertyTypesParam
      ) {
        onSearch({
          term: termParam || '',
          bedrooms: bedroomsParams.length
            ? bedroomsParams.map(b => parseInt(b))
            : [],
          radius: radiusParam,
          propertytypes: propertyTypesParam ? propertyTypesParam.split(',') : []
        });
      }

      isInitialLoad.current = false;
    }
  }, [dispatch, onSearch]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FILTERS',
      payload: {
        bedrooms: selectedBedrooms,
        propertytypes: selectedProperties,
        radius: stateRadius
      }
    });
  }, [selectedBedrooms, selectedProperties, stateRadius, dispatch]);

  const handleSearch = (overrideParams = {}) => {
    // Use the override if provided, otherwise fall back to state
    const searchTermValue =
      overrideParams.term !== undefined ? overrideParams.term : location;
    const searchBedrooms = overrideParams.bedrooms || selectedBedrooms;
    const searchRadius = overrideParams.radius || stateRadius || '40';
    const searchPropertytypes =
      overrideParams.propertytypes || selectedProperties;

    const searchParams = new URLSearchParams();
    if (searchTermValue) searchParams.set('term', searchTermValue);
    if (searchRadius) searchParams.set('radius', searchRadius);

    if (searchBedrooms.length) {
      searchBedrooms.forEach(bedroom => {
        searchParams.append('bedrooms', bedroom);
      });
    }

    if (searchPropertytypes.length) {
      searchPropertytypes.forEach(property => {
        searchParams.append('propertytypes', property);
      });
    }

    // Update the URL with the new search params
    window.history.pushState(
      {},
      '',
      `${window.location.pathname}?${searchParams}`
    );

    // Call the actual search function with the computed parameters
    onSearch({
      term: searchTermValue,
      bedrooms: searchBedrooms.length
        ? searchBedrooms.map(b => parseInt(b))
        : [],
      radius: searchRadius,
      propertytypes: searchPropertytypes
    });
  };

  const handleClearAll = () => {
    setLocation('');
    setSelectedBedrooms([]);
    setSelectedProperties([]);
    dispatch({
      type: 'SET_SEARCH_TERM',
      payload: '',
      bedrooms: [],
      propertytypes: [],
      radius: '40'
    });

    window.history.pushState({}, '', window.location.pathname);

    onSearch({
      term: '',
      bedrooms: [],
      radius: '40',
      propertytypes: []
    });
  };

  const toggleFilterMenu = () => {
    setIsFilterMenuOpen(!isFilterMenuOpen);
    document.body.style.overflow = !isFilterMenuOpen ? 'hidden' : 'auto';
  };

  const toggleView = () => {
    const newViewMode = viewMode === 'list' ? 'map' : 'list';
    setViewMode(newViewMode);
    onViewChange(newViewMode);
  };

  const handleRadiusChange = newRadius => {
    dispatch({ type: 'SET_RADIUS', payload: newRadius });
  };

  // Generate options from API data
  const propertyOptions = useMemo(() => PropertyTypes, [PropertyTypes]);

  const bedroomOptions = useMemo(() => {
    return Bedrooms.map(bed => ({
      value: bed.toString(),
      label:
        bed === 1
          ? labels?.bedroomsFilter.replace('{0}', bed).slice(0, -1)
          : labels?.bedroomsFilter.replace('{0}', bed)
    }));
  }, [Bedrooms, labels]);

  const distanceOptions = useMemo(() => {
    return Radiuses.map(radius => ({
      value: radius.toString(),
      label: `${radius} miles`
    }));
  }, [Radiuses]);

  const allShowHomes = useMemo(() => {
    return (
      results?.Developments?.reduce((acc, development) => {
        if (development.ShowHomes && development.ShowHomes.length > 0) {
          const showHomesWithDevelopment = development.ShowHomes.map(
            showHome => ({
              showHome,
              development
            })
          );
          return [...acc, ...showHomesWithDevelopment];
        }
        return acc;
      }, []) || []
    );
  }, [results?.Developments]);

  return (
    <Wrapper>
      <ShowHomesFiltersComponent>
        <FiltersWrapper>
          <SearchInput
            value={location}
            onChange={e => setLocation(e.target.value)}
            onSearch={handleSearch}
          />
          <MobileButtonsWrapper>
            <ViewToggle active={viewMode === 'list'} onClick={toggleView}>
              {viewMode === 'list' ? labels?.mapViewCta : labels?.listViewCta}
            </ViewToggle>
            <MobileFilterButton onClick={toggleFilterMenu}>
              {labels?.filtersCta}
              <img src={FilterIcon} alt="filter icon" aria-hidden="true" />
            </MobileFilterButton>
          </MobileButtonsWrapper>
        </FiltersWrapper>
      </ShowHomesFiltersComponent>

      <MobileFilterMenu isOpen={isFilterMenuOpen}>
        <div className="filter-header">
          {labels?.filtersCta && <h2>{labels?.filtersCta}</h2>}
          <CloseButton onClick={toggleFilterMenu}>×</CloseButton>
        </div>
        <DropdownsWrapper>
          <Dropdown
            label={labels?.bedroomsFilterLabel}
            type="bedrooms"
            options={bedroomOptions}
            selectedOptions={selectedBedrooms}
            onSelectionChange={setSelectedBedrooms}
          />
          <Dropdown
            label={labels?.propertyNamesFilterLabel}
            type="properties"
            options={propertyOptions}
            selectedOptions={selectedProperties}
            onSelectionChange={setSelectedProperties}
            showHomes={allShowHomes}
          />
          <div className="distance-dropdown">
            <Dropdown
              label={labels?.distanceFilterLabel}
              type="distance"
              options={distanceOptions}
              value={stateRadius}
              onChange={handleRadiusChange}
              defaultValue="40"
            />
          </div>
        </DropdownsWrapper>
        <ButtonsWrapper>
          {(searchTerm ||
            selectedBedrooms.length > 0 ||
            selectedProperties.length > 0) && (
            <>
              <ClearButton onClick={handleClearAll}>
                {labels?.clearAllCta}
              </ClearButton>
              <ApplyButton
                onClick={() => {
                  handleSearch();
                  toggleFilterMenu();
                }}
              >
                {labels?.applyCta}
              </ApplyButton>
            </>
          )}
        </ButtonsWrapper>
        {!isFilterMenuOpen && labels?.searchCta && (
          <SearchButton
            onClick={handleSearch}
            disabled={
              !location.trim() &&
              !selectedBedrooms.length &&
              !selectedProperties.length
            }
          >
            {labels?.searchCta}
          </SearchButton>
        )}
      </MobileFilterMenu>
    </Wrapper>
  );
};

export default ShowHomesFilters;
