/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import ShowHomesFilters from '../ShowHomesFilters/ShowHomesFilters.jsx';
import ShowHomesList from '../ShowHomesList/ShowHomesList.jsx';
import ShowHomesMap from '../ShowHomesMap/ShowHomesMap.jsx';
import {
  ShowHomesProvider,
  useShowHomes
} from '../../context/ShowHomesContext';
import styled from 'styled-components';
import { minBp } from '../../lib/theme.js';
import { getHostname } from '../../utility/helpers';
import { filterShowHomes } from '../../utils/filterShowHomes';

const ListMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0;

  > div {
    display: none;
  }

  > div:first-child {
    display: ${props => (props.viewMode === 'list' ? 'block' : 'none')};
  }

  > div:last-child {
    display: ${props => (props.viewMode === 'map' ? 'block' : 'none')};
  }

  @media ${minBp('tabletLarge')} {
    flex-direction: row;

    margin-top: 16px;

    > div {
      display: block !important;
      flex: 1;
      max-width: 50%;
    }
  }

  @media ${minBp('desktop')} {
    gap: 16px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ShowHomeLocatorContent = ({ apiEndpoint }) => {
  const { state, dispatch } = useShowHomes();
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [viewMode, setViewMode] = useState('list');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const searchParams = ['term', 'radius', 'bedrooms', 'propertytypes'];
    const hasExistingSearch = searchParams.some(param => params.has(param));

    if (!hasExistingSearch) {
      handleSearch({
        term: '',
        bedrooms: [],
        radius: '40',
        propertytypes: []
      });
    }
  }, []);

  const handleSearch = useCallback(
    async searchParams => {
      setIsLoading(true);
      setError(null);

      try {
        const normalizedParams = {
          term: searchParams.term || '',
          radius: searchParams.radius || '40',
          bedrooms: searchParams.bedrooms?.map(bed => Number(bed)) || [],
          propertytypes: searchParams.propertytypes || []
        };

        // Build URL with multiple bedrooms parameters
        let url = `${getHostname()}/bloorapi/showhomes?term=${encodeURIComponent(
          normalizedParams.term
        )}&radius=${normalizedParams.radius}`;

        // Add each bedroom as a separate parameter
        normalizedParams.bedrooms.forEach(bed => {
          url += `&bedrooms=${bed}`;
        });

        // Add property types if any
        if (normalizedParams.propertytypes.length) {
          url += `&propertytypes=${normalizedParams.propertytypes.join(',')}`;
        }

        const response = await fetch(url, {
          headers: {
            Accept: 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`Search failed with status ${response.status}`);
        }

        const data = await response.json();

        // Apply filters to the data
        const filteredData = {
          ...data,
          Developments: filterShowHomes(data.Developments, normalizedParams)
        };

        // Store everything in a single state update
        dispatch({
          type: 'SET_SEARCH_RESULTS',
          payload: {
            filteredResults: filteredData,
            rawResults: data,
            searchParams: normalizedParams,
            searchTerm: normalizedParams.term
          }
        });

        setSearchResults(filteredData);
        setHasSearched(true);
      } catch (err) {
        console.error('Search error:', err);
        setError(err.message);
        setSearchResults(null);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  return (
    <div>
      <ShowHomesFilters
        onSearch={handleSearch}
        onViewChange={mode => setViewMode(mode)}
      />
      <ListMapWrapper viewMode={viewMode}>
        {(hasSearched || error || state.error) && (
          <>
            <div>
              <ShowHomesList
                results={searchResults}
                isLoading={isLoading}
                error={error || state.error}
                hasSearched={hasSearched}
              />
            </div>
            {!error && !state.error && (
              <div>
                <ShowHomesMap
                  searchTerm={state.results?.Location?.Address}
                  selectedDistance={state.radius}
                  isLoading={isLoading}
                />
              </div>
            )}
          </>
        )}
      </ListMapWrapper>
    </div>
  );
};

const ShowHomeLocator = props => (
  <ShowHomesProvider>
    <ShowHomeLocatorContent {...props} />
  </ShowHomesProvider>
);

export default ShowHomeLocator;
